import React, { useEffect } from "react";
import { Router } from "@reach/router";
import {
  HomePage,
  ContactUsPage,
  DynamicPage,
  AboutUsPage,
  homeTemplatesVersionVariantTypes,
  aboutUsTemplatesVersionVariantsTypes,
  contactUsTemplatesVersionVariantsTypes,
  dynamicTemplatesVersionVariantsTypes,
  featureVariantsTypes,
  colorsTypes,
  buttonFillTypes,
  buttonStyleTypes,
  loginVariantTypes,
  textSizesVariantsTypes,
} from "@homecu/blossom-websites-core-package";

import templateVariantSettings from "config";

// import { HOMEPAGE_DATA_MODEL } from "data/cms-models";

function IndexPage() {
  //  Propiedades comunes entre los Pages/Paginas
  const commonProps = {
    // Configuracion inicial de la plantilla
    config: templateVariantSettings,
    floatHeader: true,
    topIndicatorHeader: true,
    noFilterLogoHeader: true,
    headerNoFilterMenuIcon: true,
    isTopBorder: true,
    transparent: true,
    backgroundMenuHeader: colorsTypes.TRANSPARENT,
    menuSubLinksListBorderColorHeader: colorsTypes.SECONDARY,
    menuSubLinksListBackgroundHeader: colorsTypes.HIGHT_LIGHT_PRIMARY,
    menuSubLinksTitleColorHeader: colorsTypes.PRIMARY_ULTRA_DARK,
    subLinksItemActiveTitleColorHeader: colorsTypes.PRIMARY,
    menuSubLinksItemTitleColorHeader: colorsTypes.ULTRA_LIGHT,
    navigationOptions: {},
    navigationMenuOptions: {
      subMenuGrayscaleBackgroundColor: colorsTypes.PRIMARY_VERY_ULTRA_LIGHT,
      subMenuBackgroundColor: colorsTypes.PRIMARY_VERY_ULTRA_LIGHT,
    },
    headerLoginButtonStyles: {
      variant: loginVariantTypes.NO_MODAL,
      withoutBorderRadius: templateVariantSettings?.speedBump?.borderWithRadious,
      fill: buttonFillTypes.FILLED,
      styleType: buttonStyleTypes.NONE,
      hoverFilledBackgroundColor: colorsTypes.PRIMARY_LIGHT,
      hoverFilledBorderColor: colorsTypes.PRIMARY_LIGHT,
      hoverFilledTextColor: colorsTypes.WHITE,
      radius: 10,
      padding: "2px 15px",
    },
    speedBumpStyles: {
      acceptButton: {
        fontSize: textSizesVariantsTypes.VERY_SMALL,
        withoutBorderRadius: true,
        fill: buttonFillTypes.FILLED,
        filledTextColor: colorsTypes.WHITE,
        filledBorderColor: colorsTypes.PRIMARY_DARK,
        filledBackgroundColor: colorsTypes.PRIMARY_DARK,
        hoverFilledBackgroundColor: colorsTypes.TRANSPARENT,
        hoverFilledTextColor: colorsTypes.PRIMARY_DARK,
        hoverFilledBorderColor: colorsTypes.PRIMARY_DARK,
      },
      cancelButton: {
        fontSize: textSizesVariantsTypes.VERY_SMALL,
        withoutBorderRadius: true,
        fill: buttonFillTypes.FILLED,
        filledBackgroundColor: colorsTypes.TRANSPARENT,
        filledTextColor: colorsTypes.PRIMARY_DARK,
        filledBorderColor: colorsTypes.PRIMARY_DARK,
        filledOutlineBorderColor: colorsTypes.PRIMARY_DARK,
        hoverFilledBackgroundColor: colorsTypes.PRIMARY_DARK,
        hoverFilledTextColor: colorsTypes.WHITE,
        hoverFilledBorderColor: colorsTypes.PRIMARY_DARK,
      },
    },
    footerOptions: {
      businessCustomLogos: [
        {
          replaceByIndex: 0,
          url: "https://olb-assets.s3.us-east-2.amazonaws.com/images/NCUA_Logo.svg",
          alternativeText: "NCUA Logo",
          invert: true,
          linkTo: "https://www.ncua.gov",
        },
        {
          replaceByIndex: 1,
          invert: false,
        },
        {
          replaceByIndex: 2,
          invert: false,
          linkTo: "https://www.dcuc.org",
        },

        {
          replaceByIndex: 3,
          invert: false,
          linkTo: "https://www.co-opfs.org/Shared-Branches-ATMs.html",
        },
        // {
        //   replaceByIndex: 1,
        //   alternativeText: "EQUAL Logo",
        //   invert: false,
        // },
      ],
    },
    formTemplateProps: {
      backgroundColorForm: colorsTypes.HIGHT_LIGHT_PRIMARY,
      titleFormColor: colorsTypes.BLACK,
      subtitleFormColor: colorsTypes.PRIMARY_DARK,
      textColor: colorsTypes.BLACK,
      submitButtonFilledBackgroundColor: colorsTypes.PRIMARY_DARK,
      submitButtonFilledOutlineBorderColor: colorsTypes.PRIMARY_DARK,
      submitButtonFilledTextColor: colorsTypes.WHITE,
      submitButtonHoverFilledBackgroundColor: colorsTypes.TRANSPARENT,
      submitButtonHoverFilledBorderColor: colorsTypes.PRIMARY_DARK,
      submitButtonHoverFilledTextColor: colorsTypes.PRIMARY_DARK,
    },
  };
  const footerFirstLogoOnClick = () => {
    if (!window || !window.document || !document) {
      setTimeout(footerFirstLogoOnClick, 350);
      return;
    }

    document
      .querySelectorAll(".CALLOUT_MOBILE_APPS_CENTERED_NO_BACKGROUND__CONTAINER__CONTENT__LINK")
      .forEach(node => {
        node.querySelector("a").setAttribute("target", "_self");
      });
  };

  useEffect(() => {
    setTimeout(footerFirstLogoOnClick, 1000);
  }, []);

  return (
    <Router>
      <HomePage
        path="/"
        variant={homeTemplatesVersionVariantTypes.HOME_TEMPLATE_V9}
        {...commonProps}
        // Forzar el uso de un Template
        // forceCustomVariant
        // variant={homeTemplatesVersionVariantTypes.HOME_TEMPLATE_V5}
        // Personalizar boton de login en el menu de navegacion
        headerLoginButtonStyles={{
          variant: loginVariantTypes.NO_MODAL,
          //  Habilitar o des-habilitar escala de grises
          // grayscale: true,
          //  Cambiar el color de fondo en su estado (hover) y con la escala de grises habilitada
          // hoverGrayscaleFillBackgroundColor: "rgba(196, 196, 196, 0.5)",
          //  Cambiar el color de bordes en su estado (hover) y con la escala de grises habilitada
          // hoverGrayscaleFillBorderColor: colorsTypes.WHITE,
          //  Cambiar el color de textos en su estado (hover) y con la escala de grises habilitada
          hoverGrayscaleFilledTextColor: colorsTypes.WHITE,
        }}
        headerMobileLoginButtonStyles={{
          variant: loginVariantTypes.NO_MODAL,
        }}
        // Personalizar Hero
        heroProps={{
          // Personalizar estilos de los botones
          buttonStylesProps: {
            //  Habilitar o des-habilitar escala de grises
            // grayscale: true,
            // Cambiar color de fondo en su estado (hover) y cuando esta habilitada la escala de grises
            // hoverGrayscaleFillBackgroundColor: "rgba(196, 196, 196, 0.5)",
            // Cambiar color de bordes en su estado (hover) y cuando esta habilitada la escala de grises
            // hoverGrayscaleBorderColor: colorsTypes.WHITE,
            // Cambiar color de textos en su estado (hover) y cuando esta habilitada la escala de grises
            // hoverGrayscaleFilledTextColor: colorsTypes.WHITE,
          },
        }}
        // Personalizar Lista de Productos e items
        productsProps={{}}
        // Personalizar Feature
        featureProps={{
          variant: featureVariantsTypes.V6,
        }}
        // Personalizar Feature Full
        featureFullProps={{}}
        // Personalizar Callout
        calloutProps={{}}
        // Personalizar Callout Mobile
        calloutMobileProps={{}}
        // Personalizar Callout Compressed
        calloutCompressedProps={{}}
      />

      <AboutUsPage
        path="/about-us"
        variant={aboutUsTemplatesVersionVariantsTypes.ABOUT_US_TEMPLATE_V8}
        transparent={false}
        headerMobileTransparentBackground="white"
        {...commonProps}
        headerLoginButtonStyles={{
          variant: loginVariantTypes.NO_MODAL,
        }}
        headerMobileLoginButtonStyles={{
          variant: loginVariantTypes.NO_MODAL,
        }}
        // Personalizar Page Header
        pageHeaderProps={{}}
        // Personalizar Body Text
        bodyTextProps={{}}
        // Personalizar Callout Text
        calloutTextProps={{}}
        // Personalizar Callout Button
        calloutButtonProps={{}}
      />

      <ContactUsPage
        path="/contact-us"
        variant={contactUsTemplatesVersionVariantsTypes.CONTACT_US_TEMPLATE_V10}
        transparent={false}
        headerMobileTransparentBackground="white"
        {...commonProps}
        // Personalizar Submit Button
        submitButton={{}}
        // Personalizar Header
        pageHeaderProps={{}}
        // Personalizar Page Title
        pageTitleProps={{}}
        // Personalizar Contact
        contactProps={{}}
        // Personalizar Branch
        branchProps={{}}
        // Personalizar  Callout
        calloutProps={{}}
        // Personalizar  Callout Mobile With Gradient
        calloutMobileWithGradientPros={{}}
        headerLoginButtonStyles={{
          variant: loginVariantTypes.NO_MODAL,
        }}
        headerMobileLoginButtonStyles={{
          variant: loginVariantTypes.NO_MODAL,
        }}
      />

      <DynamicPage
        path="/:id"
        variant={dynamicTemplatesVersionVariantsTypes.DYNAMIC_TEMPLATE_V9}
        transparent={false}
        headerMobileTransparentBackground="white"
        {...commonProps}
        headerLoginButtonStyles={{
          variant: loginVariantTypes.NO_MODAL,
        }}
        headerMobileLoginButtonStyles={{
          variant: loginVariantTypes.NO_MODAL,
        }}
        // Personalizar Custom Page
        customPageProps={{}}
        // Personalizar Hero
        heroProps={{}}
        // Personalizar Lista de Productos
        productsProps={{}}
        // Personalizar Feature Full
        featureFullProps={{}}
        // Personalizar Feature
        featureProps={{}}
        // Personalizar Callout
        calloutProps={{}}
        // Personalizar Callout Mobile
        calloutMobileProps={{}}
        // Personalizar Callout Compressed
        calloutCompressedProps={{}}
      />
    </Router>
  );
}

export default IndexPage;
